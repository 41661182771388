import React from 'react';
import './Line.css'; // CSS file for styling
import BaseComponent from '../../base/Base';

export function VerticalLine({style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="v-line-1">
    </BaseComponent>
  )
}

function Line({style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="line-1">
    </BaseComponent>
  )
}

export default Line;