import React from 'react';
import BaseComponent from '../../base/Base';

import './FlexGridRow.css'

function FlexGridRowComponent({children, className}) {
  return (
    <BaseComponent className={className} primaryClassName="flex-grid-row">
      {children}
    </BaseComponent>
  )
}

export function FlexGridColumnComponent({children, className}) {
  return (
    <BaseComponent className={className} primaryClassName="flex-grid-col">
      {children}
    </BaseComponent>
  )
}

export function FlexGridItem({children, className}) {
  return (
    <BaseComponent className={className} primaryClassName="flex-grid-item">
      {children}
    </BaseComponent>
  )
}
export default FlexGridRowComponent